<template>
  <div class="page-login">
    <main>
      <div class="left">
        <a-form
          v-if="form.loginType === 1"
          ref="formRef"
          :model="form"
          class="login-form"
        >
          <div class="loginTitle">账号登录</div>
          <a-form-item
            name="username"
            :rules="[{ required: true, message: '请输入账号！' }]"
          >
            <div class="itemTitle">
              <span class="label">账号</span>
              <a-button class="myBtn" type="link" @click="changeLogintype(2)">
                手机号登录
              </a-button>
            </div>
            <a-input
              placeholder="请输入登录账号"
              v-model:value="form.username"
              allow-clear
              size="large"
              @keyup.enter="doSubmit()"
            />
          </a-form-item>
          <a-form-item
            name="password"
            :rules="[{ required: true, message: '请输入密码！' }]"
          >
            <div class="itemTitle">
              <span class="label">密码</span>
              <!-- <a-button class="myBtn" type="link">忘记密码?</a-button> -->
            </div>
            <a-input-password
              placeholder="请输入登录密码"
              v-model:value="form.password"
              size="large"
              @keyup.enter="doSubmit()"
            />
          </a-form-item>
        </a-form>
        <a-form
          v-if="form.loginType === 2"
          ref="formRef"
          :model="form"
          class="login-form"
        >
          <div class="loginTitle">手机号码登录</div>
          <a-form-item
            name="mobileNo"
            :rules="[
              { required: true, message: '请输入手机号！' },
              { pattern: /^1\d{10}$/, message: '请输入正确的手机号！' },
            ]"
          >
            <div class="itemTitle">
              <span class="label">手机号码</span>
              <a-button class="myBtn" type="link" @click="changeLogintype(1)">
                账号登录
              </a-button>
            </div>
            <a-input
              placeholder="请输入手机号"
              v-model:value="form.mobileNo"
              allow-clear
              size="large"
              @keyup.enter="doSubmit()"
            />
          </a-form-item>
          <!-- <a-form-item
            name="imgCode"
            :rules="[{ required: true, message: '请输入图形验证码！' }]"
          >
            <div class="itemTitle">
              <span class="label">图形验证码</span>
            </div>
            <a-input
              placeholder="请输入图形验证码"
              v-model:value="form.imgCode"
              allow-clear
              size="large"
            >
              <template #suffix>
                <img
                  src=""
                  alt="验证码img"
                  style="cursor: 'pointer'; width: 100; height: 21"
                  @Click="getCaptcha"
                />
              </template>
            </a-input>
          </a-form-item> -->
          <a-form-item
            name="mobileCode"
            :rules="[
              { required: true, message: '请输入验证码！' },
              { pattern: /^\d{4}$/, message: '验证码长度错误！' },
            ]"
          >
            <div class="itemTitle">
              <span class="label">验证码</span>
            </div>
            <a-input
              maxLength="4"
              placeholder="请输入验证码"
              v-model:value="form.mobileCode"
              size="large"
              @keyup.enter="doSubmit()"
            >
              <template #suffix>
                <a-button
                  type="primary"
                  @click="getMobileCode"
                  :disabled="timing"
                  :loading="sendSmsLoading"
                >
                  {{
                    timing ? `${Math.round(countdown / 1000)} 秒` : "获取验证码"
                  }}
                </a-button>
              </template>
            </a-input>
          </a-form-item>

          <!-- <div class="autoLoginItem">
          <a-checkbox v-model:checked="autoLogin">自动登录</a-checkbox>
          <div>
            <span class="tips">还没有用户？</span>
            <router-link
              to="/"
              class="ele-pull-right"
              style="line-height: 22px"
            >
              立即注册
            </router-link>
          </div>
        </div> -->
        </a-form>
        <a-button
          style="height: 40px"
          block
          size="large"
          type="primary"
          :loading="loading"
          @click="doSubmit()"
        >
          {{ loading ? "登录中" : "登录" }}
        </a-button>
      </div>
      <div class="right">
        <div class="rigtTitle">
          <h1>象征云</h1>
          <span>Xz Cloud</span>
        </div>
        <div class="img"></div>
        <div class="login-copyright">
          Copyright © 2017-{{ new Date().getFullYear() }}
          杭州象征信息技术有限公司
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import setting from "@/config/setting";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store/index";
import { useForm, useSendSms } from "./use";
import { goingLogin } from "@/config/axios-config";
import { useCountDown } from "xz-use";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    // onMounted(() => {
    //   if (setting.takeToken()) {
    //     goHome();
    //   }
    // });
    const autoLogin = ref<any>(false);
    let loading = ref<any>(false);
    const timing = ref<boolean>(false);
    const { countdown, setTargetDate } = useCountDown({
      onEnd: () => {
        timing.value = false;
      },
    });
    const checkImageId = ref<string>(""); // img验证码id
    const checkImageUrl = ref<string>(""); // img验证码url
    const imgCodeIsTrue = ref<boolean>(false);
    const { form, formRef } = useForm();
    const { run: sendSmsRun, loading: sendSmsLoading } = useSendSms();
    function trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    }

    const redirect = computed(() => {
      return route.query?.redirect || "/system/selectSystem";
    });

    const otherQuery = computed(() => {
      return Object.keys(route.query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = route.query[cur];
        }
        return acc;
      }, {});
    });

    onMounted(() => {
      goingLogin.value = false;
    });

    // 登录
    const doSubmit = async () => {
      loading.value = true;
      try {
        await formRef.value?.validateFields();
        if ((form.value as any)?.username) {
          (form.value as any).username = trim((form.value as any).username);
        }
        if ((form.value as any)?.mobileNo) {
          (form.value as any).mobileNo = trim((form.value as any).mobileNo);
        }
        await store.dispatch("user/Login", {
          ...form.value,
        });
        router.replace({
          path: redirect.value as any,
          query: otherQuery.value,
        });
      } catch (e) {
        console.error(e);
      }
      loading.value = false;
    };

    const goHome = () => {
      // const query = route.query;
      // const path: string | null = query && query.from ? query.from : "/";
      // router.push(path).catch(() => {});
    };

    // 登录方式切换
    const changeLogintype = (loginType) => {
      form.value.loginType = loginType;

      if (loginType === 1) {
        form.value = {
          loginType: 1,
          password: "",
          username: (form.value as any).mobileNo,
        };
      } else {
        form.value = {
          loginType: 2,
          mobileCode: "",
          mobileNo: (form.value as any).username,
          // imgCode: "",
        };
      }
    };

    //获取图片验证码
    const getCaptcha = () => {
      console.log("获取图片验证码");
      // const {
      //   data: { data },
      // } = await get(`/sms/getCaptcha?${getUniqueId()}`);
      // setCheckImageId(data.id);
      // const url = getUrl(`/captcha/image/${data.id}?reload=true`);
      // setCheckImageUrl(url);
    };

    // 获取手机验证码
    const getMobileCode = async () => {
      // await formRef.value?.validateFields(["mobileNo", "imgCode"]);
      await formRef.value?.validateFields(["mobileNo"]);
      const mobileNo = (form.value as any).mobileNo;
      await sendSmsRun({ mobileNo, type: 1 });
      timing.value = true;
      setTargetDate(Date.now() + 60000);
    };

    return {
      form,
      formRef,
      loading,
      timing,
      // imgCodeIsTrue,
      sendSmsLoading,
      countdown,
      // autoLogin,
      doSubmit,
      changeLogintype,
      // getCaptcha,
      getMobileCode,
    };
  },
});
</script>
<style lang="less" scoped>
.page-login {
  width: 100vw;
  height: 100vh;
  main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 750px;
    margin: 0 auto;
    display: flex;
  }
  .left {
    margin: 150px 160px 0 0;
  }
  .login-form {
    width: 364px;
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    color: #393c42;
    font-family: PingFangSC-Medium, PingFang SC;
    .loginTitle {
      margin-bottom: 48px;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }
    .itemTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .label {
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
      .myBtn {
        padding: 0;
      }
    }
    .autoLoginItem {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .tips {
        color: #bbb;
      }
    }
  }
  .right {
    width: 537px;
    .rigtTitle {
      text-align: center;
      margin: 108px 0 40px 0;
      h1 {
        margin-bottom: 24px;
        font-size: 36px;
        font-weight: 500;
        color: #393c42;
        line-height: 28px;
      }
      span {
        display: inline-block;
        font-size: 12px;
        font-family: Avenir-Light, Avenir;
        font-weight: 300;
        color: #8a909f;
        line-height: 28px;
      }
    }
    .img {
      width: 537px;
      height: 357px;
      background-image: url(../../../assets/images/login/img1.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .login-copyright {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      color: #8a909f;
    }
  }
}

.login-form-right .login-form {
  margin: 0 15% 0 auto;
}

.login-form-left .login-form {
  margin: 0 auto 0 15%;
}

.login-form h4 {
  padding: 22px 0;
  text-align: center;
}

/* 验证码 */
.login-input-group {
  display: flex;
  align-items: center;
}

.login-input-group :deep(.ant-input-affix-wrapper) {
  flex: 1;
}

.login-input-group .login-captcha {
  width: 102px;
  height: 40px;
  margin-left: 10px;
  padding: 0;
}

.login-input-group .login-captcha > img {
  width: 100%;
  height: 100%;
}

/* 第三方登录图标 */
.login-oauth-icon {
  color: #fff;
  padding: 5px;
  margin: 0 12px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
